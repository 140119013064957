import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UrlrouterComponent } from './urlrouter/urlrouter.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'registration',
    loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardPageModule)
  },
  {
    path: 'editprofile',
    loadChildren: () => import('./editprofile/editprofile.module').then(m => m.EditprofilePageModule)
  },
  {
    path: 'newsocial',
    loadChildren: () => import('./newsocial/newsocial.module').then(m => m.NewsocialPageModule)
  },
  {
    path: 'qrcode',
    loadChildren: () => import('./qrcode/qrcode.module').then(m => m.QrcodePageModule)
  },
  {
    path: 'delete',
    loadChildren: () => import('./delete/delete.module').then(m => m.DeletePageModule)
  },
  {
    path: 'savecontact',
    loadChildren: () => import('./savecontact/savecontact.module').then(m => m.SavecontactPageModule)
  },
  {
    path: 'fblogin',
    loadChildren: () => import('./fblogin/fblogin.module').then(m => m.FbloginPageModule)
  },
  {
    path: 'reset',
    loadChildren: () => import('./reset/reset.module').then( m => m.ResetPageModule)
  },

  {
    path: 'love',
    loadChildren: () => import('./love/love.module').then( m => m.LovePageModule)
  },

  {
    path: 'avatar',
    loadChildren: () => import('./avatar/avatar.module').then( m => m.AvatarPageModule)
  },
  { path: ':id', component: UrlrouterComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }