import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { User } from '../shared/services/User';
import { LoveUser } from '../shared/services/LoveUser';
import { environment } from '../../environments/environment';
import { MyServiceService } from '../my-service.service';



@Component({
  selector: 'app-urlrouter',
  templateUrl: './urlrouter.component.html',
  styleUrls: ['./urlrouter.component.scss'],
})
export class UrlrouterComponent implements OnInit {
  public uid: string;

  constructor(private activatedRoute: ActivatedRoute, private afs: AngularFirestore, private router: Router, public loadingController: LoadingController,private myService: MyServiceService
  ) {
  }

  ngOnInit() {
    this.presentLoading();
    this.uid = this.activatedRoute.snapshot.paramMap.get('id').toLocaleLowerCase();
    console.log('id is ' + this.uid);
    //check in database
    //0494f8da9d7180
    //1007f8da9d7180 --> 11-19 key chain, 07 photo print (change key chain to love card)

    //test id from 24/02/2023: 1107003816f0ac
    if (this.myService.isLoveLoveCard(this.uid)) {
      console.log("islovecard: true");

      //lovecard
      this.afs.doc<LoveUser>(this.myService.COLLECTION_NAME_LOVEUSER +'/' + this.uid).valueChanges().subscribe(
        dataUser => {
          if (dataUser == null) {
            //chưa tồn tại trên hệ thống
            console.log("Does not exist.2");
            //chuyển sang trang đăng ký bình thường
            this.router.navigate(['home']);
            this.loadingController.dismiss();
          } else {
            //đã kích hoạt
            if (dataUser.status == environment.status1cham.USER_STATUS_ACTIVED) {
              //chuyển sang profiles nếu k
              this.router.navigate(['love'], { queryParams: { "uid": this.uid } });
              this.loadingController.dismiss();
            } else {
              console.log("cần đăng ký");
              //cần đăng ký nếu tồn tại và status=0
              //this.router.navigate(['registration?uid=' + this.uid]);
              this.router.navigate(['registration'], { queryParams: { uid: this.uid } });
              this.loadingController.dismiss();
            }
          }
        })


    } else {
      // this.firestore.collection('users', ref => ref.where('uid', '==', this.uid).where('status', '==', 0)).snapshotChanges().subscribe(res => {
      this.afs.doc<User>('users/' + this.uid).valueChanges().subscribe(
        dataUser => {
          if (dataUser == null) {
            //chưa tồn tại trên hệ thống
            console.log("Does not exist.2");
            //chuyển sang trang đăng ký bình thường
            this.router.navigate(['home']);
            this.loadingController.dismiss();
          } else {
            //đã kích hoạt
            if (dataUser.status == environment.status1cham.USER_STATUS_ACTIVED) {
              //console.log("Match found. go to dashboard");
              console.log("dataUser.redirect= " + dataUser.redirect);
              //kiểm tra redirect của user
              if (dataUser.redirect) {
                //chuyển hướng sang trang đầu tiên trong danh sách
                //nếu liên hệ đầu tiên không có sẽ chuyển hướng bình thường
                var mySocialsList = dataUser.socials;
                if (mySocialsList != null && mySocialsList.length > 0) {
                  //lấy link
                  var key = mySocialsList[0]["key"];
                  var value = mySocialsList[0]["value"];
                  if (value.length > 0) {
                    this.afs.doc<User>('socials/' + key).valueChanges().subscribe(
                      dataSocial => {

                        if (dataSocial == null) {
                          this.router.navigate(['home']);
                          this.loadingController.dismiss();

                        } else {
                          var link = dataSocial["prelink"] + value
                          console.log("link = " + link);
                          // window.open(link, "_self");
                          window.location = link;
                          //window.open(link, "_blank");
                        }
                      })
                  } else {
                    //chuyển sang profiles nếu k
                    this.router.navigate(['dashboard'], { queryParams: { "uid": this.uid } });
                    this.loadingController.dismiss();
                  }
                } else {
                  //chuyển sang profiles nếu k
                  this.router.navigate(['dashboard'], { queryParams: { "uid": this.uid } });
                  this.loadingController.dismiss();
                }
              } else {
                //chuyển sang profiles nếu k
                this.router.navigate(['dashboard'], { queryParams: { "uid": this.uid } });
                this.loadingController.dismiss();
              }

            } else {
              console.log("cần đăng ký");
              //cần đăng ký nếu tồn tại và status=0
              //this.router.navigate(['registration?uid=' + this.uid]);
              this.router.navigate(['registration'], { queryParams: { uid: this.uid } });
              this.loadingController.dismiss();
            }
          }
        })
    }


  }

  async presentLoading() {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Đang tải...',
      duration: 5000
    });
    await loading.present();
    const { role, data } = await loading.onDidDismiss();
  }

}
