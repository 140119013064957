import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AuthService } from './auth-service.service';
import { AngularFireAuthModule } from "@angular/fire/auth";
import { ImageCropperModule } from 'ngx-image-cropper';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { HeaderWithIconComponent } from './header-with-icon/header-with-icon.component';
import { AngularFireAnalyticsModule, DEBUG_MODE, ScreenTrackingService } from '@angular/fire/analytics';
import { QRCodeModule } from 'angularx-qrcode';
import { HttpClientModule} from '@angular/common/http';
import { AngularFireFunctionsModule , USE_EMULATOR } from "@angular/fire/functions";

@NgModule({
  declarations: [AppComponent, HeaderWithIconComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    ImageCropperModule,
    AngularFireStorageModule,
    AngularFireAnalyticsModule,
    QRCodeModule,
    HttpClientModule,
    AngularFireFunctionsModule,
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, AuthService, ScreenTrackingService, { provide: DEBUG_MODE, useValue: false }
    ,    { provide: USE_EMULATOR, useValue: ['localhost', 4200] }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
