import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-with-icon',
  templateUrl: './header-with-icon.component.html',
  styleUrls: ['./header-with-icon.component.scss'],
})
export class HeaderWithIconComponent implements OnInit {
  @Input() content: string;

  constructor() { }

  ngOnInit() { }

}
