import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MyServiceService {

  constructor() { }
  COLLECTION_NAME_USER = 'users'
  COLLECTION_NAME_LOVEUSER = 'loveusers'
  STORAGE_BUCKET_AVATAR = 'ChamToiAvatarImages'
  isLoveLoveCard(uid) {
    if (uid == null) {
      return false
    }

    console.log("checking uid:" + uid);
    if (uid.startsWith('11') || uid.startsWith('12') || uid.startsWith('13') || uid.startsWith('14') || uid.startsWith('15') || uid.startsWith('16') || uid.startsWith('17') || uid.startsWith('18') || uid.startsWith('19')) {
      return true;
    }
    return false;
  }
  getCollectionName(uid) {
    if (uid == null) {
      return "users"
    }
    if (this.isLoveLoveCard(uid)) {
      return "loveusers"
    } else {
      return "users"
    }
  }
  getNameOfCard(uid) {
    if (this.isLoveLoveCard(uid)) {
      return "1 Chạm Love"
    } else {
      return "1 Chạm"
    }
  }

  getFilename(url: string, bucketName: string) {
    var pre1 = "%2F";
    var pre2 = "?alt=media";
    if (url.includes(bucketName) && url.includes(pre1) && url.includes(pre2)) {
      var fileName = url.substring((url.indexOf(bucketName) + bucketName.length + pre1.length), url.indexOf(pre2));
      return fileName;
    } else {
      return null;
    }
  }

}
