import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth, AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from "@angular/router";
import { User } from './shared/services/User';
import firebase from 'firebase/app';
import 'firebase/auth';
import { LoadingController, ToastController } from '@ionic/angular';
import { MyServiceService } from './my-service.service';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public afAuthModule: AngularFireAuthModule
    , // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    public loadingController: LoadingController,
    public toastController: ToastController,
    private myService: MyServiceService
  ) {
    /* Saving user data in localstorage when 
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe(user => {
      if (user) {
        console.log("login success");
        //đăng nhập thành công bằng firebase authen
      } else {
        console.log("logout ");
        this.setCurrentUser(null);
      }
    })
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null) ? true : false;
  }

  // Sign in with Google
  GoogleAuth(uid1Cham) {
    if (this.myService.isLoveLoveCard(uid1Cham))
      return this.AuthLogin(new firebase.auth.GoogleAuthProvider(), uid1Cham, 'love');
    else
      return this.AuthLogin(new firebase.auth.GoogleAuthProvider(), uid1Cham, 'dashboard');
  }
  FacebookAuth(uid1Cham) {
    return this.AuthLogin(new firebase.auth.FacebookAuthProvider(), uid1Cham, 'dashboard');
  }

  // reset with Google
  GoogleAuthReset(uid1Cham) {
    return this.AuthLogin(new firebase.auth.GoogleAuthProvider(), uid1Cham, 'reset');
  }
  // delete with Google
  GoogleAuthDelete(uid1Cham) {
    return this.AuthLogin(new firebase.auth.GoogleAuthProvider(), uid1Cham, 'delete');
  }
  FacebookAuthDelete(uid1Cham) {
    return this.AuthLogin(new firebase.auth.FacebookAuthProvider(), uid1Cham, 'delete');
  }

  // Auth logic to run auth providers
  AuthLogin(provider, uid1Cham, pageToNaviage) {
    this.presentLoading();
    return this.afAuth.signInWithPopup(provider)
      .then((result) => {
        this.SetUserData(result, uid1Cham, pageToNaviage);
      }).catch((error) => {
        // window.alert(error)
        console.log(error)
      })
  }

  /* Setting up user data when sign in with username/password, 
  sign up with username/password and sign in with social auth  
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(result, uid1Cham, pageToNaviage) {
    if (uid1Cham) {
      uid1Cham = uid1Cham.toLowerCase().trim();
    }

    console.log("type=" + result.operationType);
    console.log("uid1Cham = " + uid1Cham + " (null if login)");

    var userLoginned = this.afs.collection(this.myService.getCollectionName(uid1Cham), ref => ref.where('uidFirebase', '==', result.user.uid)
      .where('status', '==', 1)).snapshotChanges()
      .subscribe(res => {
        userLoginned.unsubscribe();

        //double check if user not exist
        if (uid1Cham == null && res.toString().trim().length <= 0) {
          var userLoginned1 = this.afs.collection(this.myService.COLLECTION_NAME_LOVEUSER, ref => ref.where('uidFirebase', '==', result.user.uid)
            .where('status', '==', 1)).snapshotChanges()
            .subscribe(res1 => {
              userLoginned1.unsubscribe();
              this.processAuthen(result, res1, 'love', uid1Cham)
              this.loadingController.dismiss()
            });
        } else {
          this.processAuthen(result, res, pageToNaviage, uid1Cham)
          this.loadingController.dismiss()
        }
      });
  }

  // Sign out 
  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['login']);
    })
  }

  setCurrentUser(currentUser) {
    localStorage.setItem('user', JSON.stringify(currentUser));
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  async presentLoading() {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Vui lòng đợi...',
      duration: 20000
    });
    await loading.present();
    const { role, data } = await loading.onDidDismiss();
    console.log('Loading dismissed!');
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000
    });
    toast.present();
  }

  processAuthen(result: any, res, pageToNaviage, uid1Cham) {

    if (res.toString().trim().length > 0) {
      //user đã tồn tại --> đăng nhập vào hệ thống
      var data = <User>res.pop().payload.doc.data();
      console.log("sign in success = " + data.displayName);
      console.log("uidFirebase = " + result.user.uid);
      console.log("res = " + res);
  
      this.setCurrentUser(data);
      // chuyển hướng sang trang navitate
      this.router.navigate([pageToNaviage])
        .then(() => {
          window.location.reload();
        });
    } else {
      console.log("sign up")
      if (uid1Cham == null) {
        this.setCurrentUser(null);
        //chuyển sang đăng ký
        this.router.navigate(['registration']);
        this.presentToast('Vui lòng đăng ký tài khoản trước khi đăng nhập')
      } else {
        //đăng ký
        this.afs.doc(this.myService.getCollectionName(uid1Cham) + `/${uid1Cham}`).set({
          uidFirebase: result.user.uid,
          email: result.user.email,
          displayName: result.user.displayName,
          name: result.user.displayName,
          photoURL: result.user.photoURL,
          emailVerified: result.user.emailVerified,
          created: new Date(),
          redirect: false,
          status: 1,
          uid1Cham: uid1Cham,
        }, {
          merge: true
        })
  
          .then(() => {
            //get data
            var user1 = this.afs.collection(this.myService.getCollectionName(uid1Cham), ref => ref.where('uidFirebase', '==', result.user.uid)
              .where('status', '==', 1)).snapshotChanges()
              .subscribe(res => {
                user1.unsubscribe();
                var data = <User>res.pop().payload.doc.data();
                this.setCurrentUser(data);
                this.router.navigate([pageToNaviage]).then(() => {
                  window.location.reload();
                });
              })
          }).catch(err => {
            // handle error
          });
  
      }
    }
  }

  
}

